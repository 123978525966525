import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import TraverseOauthLogin from '../../../Components/Authentication/TraverseOAuth/TraverseOauthLogin'
import {
  authService,
  eventService,
  localStorageService,
  PreferenceType,
} from '../../../Services/services-index'
import * as qs from 'qs'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#1E1E1E',
    '& div[class*="MuiGrid-spacing"]': {
      margin: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '100vh',
      },
    },
    '& div[class*="MuiGrid-item"]': {
      padding: '0 24px',
    },
  },
  item: {
    width: '100%',
  },
}))

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const TraverseOauthContainer: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const location: any = useLocation()
  const query = useQuery()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [callbackCode, setCallbackcode] = useState<any>()
  const [eventCodeFromQuery] = useState<string>('')
  const [hasEventBaseDeskEmailId, setHasEventBaseDeskEmailId] = useState<boolean>(false)
  const [eventBaseHelpDeskEmailId, setEventBaseHelpDeskEmailId] = useState<string>('')

  const { from } = location.state || { from: { pathname: '/' } }

  useEffect(() => {
    const callbackCode = query.get('code')
    setCallbackcode(callbackCode)
  }, [callbackCode])

  const onSubmit = async () => {
    traverseOauthSignIn()
  }

  const traverseOauthSignIn = async () => {
    const client_id = localStorageService.getLocalStorageItemValue('traverseClientID')
    const redirect_url = localStorageService.getLocalStorageItemValue('traverseRedirectURL')
    const state = localStorageService.getLocalStorageItemValue('traverseState')

    //Still data not available
    if (!client_id && !redirect_url && !state) {
      setErrorMessage('Client id/ redirect url/ state is not defined!')
    } else {
      const currentUser = localStorageService.getLocalStorageItemValue('currentUser')

      const usrData = JSON.parse(currentUser)
      const jwtToken = `Bearer ${usrData.jwt_token}`

      const authCode = await authService.authCode(
        { client_challenge: state },
        { Authorization: jwtToken }
      )

      if (authCode) {
        const params = {
          code: authCode.data.auth_code,
        }
        const code = qs.stringify(params)
        let url: string
        if (redirect_url.includes('?')) {
          url = `${redirect_url}&${code}`
        } else {
          url = `${redirect_url}?${code}`
        }

        //clear once used if its saved
        localStorageService.clearLocalStorageItemValue('traverseClientID')
        localStorageService.clearLocalStorageItemValue('traverseRedirectURL')
        localStorageService.clearLocalStorageItemValue('traverseState')

        window.location.replace(url)
      } else {
        history.replace(from)
        history.push('/auth/login')
      }
    }
  }

  useEffect(() => {
    if (eventCodeFromQuery && !eventBaseHelpDeskEmailId) {
      eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'help_desk_email',
          event_code: eventCodeFromQuery.toString(),
        })
        .then((res: any) => {
          const response = res?.data?.[0]
          if (response && response?.value?.length) {
            setHasEventBaseDeskEmailId(true)
            setEventBaseHelpDeskEmailId(response?.value)
          }
        })
        .catch((err: any) => {
          console.error('An error occurred:', err.message)
          throw new Error('An unexpected error occurred. Please try again later.')
        })
    }
  }, [eventCodeFromQuery])

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <TraverseOauthLogin errorMessage={errorMessage} onClick={onSubmit}></TraverseOauthLogin>
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter
            hasEventBaseDeskEmailId={hasEventBaseDeskEmailId}
            eventBaseHelpDeskEmailId={eventBaseHelpDeskEmailId}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default TraverseOauthContainer
