import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { eventService } from '../Services/services-index'

export interface Preference {
  id: number
  user_id: number
  event_id: number
  room_id: number
  role_id: number
  category: string
  value: string
  name: string
  from_date: string
  to_date: string
  type: string
  subcategory: string
}

export interface PreferencesSlice {
  preferences?: Preference[]
  specificPreferences?: Preference[]
  selectedPreferences?: PreferenceStatus
}

const initialState: PreferencesSlice = {
  preferences: undefined,
  specificPreferences: undefined,
  selectedPreferences: undefined,
}

// TODO delete PreferenceStatus, preferences and setPreferences when we use all available preferences
export interface PreferenceStatus {
  [key: string]: Preference | undefined
  room_chat?: Preference
  private_chat?: Preference
  business_cards?: Preference
  calls?: Preference
  room_help?: Preference
  mini_map?: Preference
  exit_survey?: Preference
  exit_survey_url?: Preference
  passport_badges?: Preference
  javascript_monitoring?: Preference
  transition_content?: Preference
  experience_bar?: Preference
  fpv_hand?: Preference
  show_job_title?: Preference
  show_company?: Preference
  background_music?: Preference
  mini_map_at_start?: Preference
  enable_token_wallet?: Preference
  use_invisit_hotspot?: Preference
  hide_camera_toggle?: Preference
}

const preferences: PreferenceStatus = {
  room_chat: undefined,
  private_chat: undefined,
  business_cards: undefined,
  calls: undefined,
  room_help: undefined,
  mini_map: undefined,
  exit_survey: undefined,
  exit_survey_url: undefined,
  passport_badges: undefined,
  javascript_monitoring: undefined,
  transition_content: undefined,
  experience_bar: undefined,
  fpv_hand: undefined,
  show_job_title: undefined,
  show_company: undefined,
  background_music: undefined,
  mini_map_at_start: undefined,
  enable_token_wallet: undefined,
  use_invisit_hotspot: undefined,
  hide_camera_toggle: undefined,
}

const setPreferences = (eventPreferences: { [name: string]: Preference }): PreferenceStatus => {
  const pref: PreferenceStatus = { ...preferences }
  if (eventPreferences) {
    Object.keys(eventPreferences).forEach((preference) => {
      if (eventPreferences[preference]) {
        pref[preference] = eventPreferences[preference]
      }
    })
  }
  return pref
}

export const fetchPreferences = createAsyncThunk(
  'event/fetchPreferences',
  async (payload: any, { dispatch }) => {
    eventService
      .getPreference({
        type: payload.type,
        room: payload.room,
      })
      .then((response: any) => {
        const data: PreferencesSlice = response.data
        const preferences = {}

        if (data?.specificPreferences) {
          data.specificPreferences.forEach(({ name, ...rest }) => {
            Object.assign(preferences, {
              [name]: { ...rest },
            })
          })
          if (preferences) {
            dispatch(updateEventPreferences(preferences))
          }
          // TODO modify this when we use all available preferences
          const selectedPreferences = setPreferences(preferences)
          if (selectedPreferences) {
            dispatch(setSelectedPreferences(selectedPreferences))
          }
        }
      })
      .catch((err) => {
        console.error('An error occurred:', err.message)
        throw new Error('An unexpected error occurred. Please try again later.')
      })
  }
)

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    updateEventPreferences: (state: typeof initialState, action: PayloadAction<any>) => {
      action.payload.preferences
        ? (state.preferences = { ...action.payload })
        : (state.specificPreferences = { ...action.payload })
    },
    setSelectedPreferences: (state: typeof initialState, action: PayloadAction<any>) => {
      state.selectedPreferences = action.payload
    },
  },
})

export const { updateEventPreferences, setSelectedPreferences } = preferencesSlice.actions

export default preferencesSlice.reducer
