import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import FormTextField from '../FormTextField/FormTextField'
import { ShadowedButton } from '../../../Common/common-index'

interface Props {
  eventCode: string | undefined
  eventCodeFromQuery: string | undefined
  eventFieldError?: boolean | undefined
  errorMessage?: string | undefined
  onEventValueChange: () => void
  onClick: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0),
          width: '400px',
        },
      },
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'start',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      color: 'white',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    description: {
      color: 'white',
      fontSize: '16px',
      lineHeight: '24px',
      margin: theme.spacing(5, 2),
      [theme.breakpoints.up('md')]: {
        width: '400px',
        margin: theme.spacing(5, 0),
      },
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
      color: 'white',
    },
    errorStatus: {
      color: theme.palette.error.main,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        maxWidth: '400px',
      },
    },
    input: {
      '&:before': {
        borderBottom: '1px solid #000 !important', // ensures the line is always visible
      },
      '&:hover:before': {
        borderBottom: '1px solid #000 !important', // ensures the line is always visible on hover
      },
      '&:after': {
        borderBottom: '2px solid #000 !important', // ensures the line is always visible when focused
      },
      color: 'white',
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '36px',
      width: '100%',
      textAlign: 'justify',
      margin: theme.spacing(1, 0),
      boxSizing: 'border-box',
    },
    inputContainer: {
      color: 'white',
    },
  })
)

const OAuth2Login: FC<Props> = ({
  eventCode,
  eventCodeFromQuery,
  eventFieldError,
  errorMessage,
  onEventValueChange,
  onClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // On keyboard "Enter" key enter
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onClick()
    }
  }

  let loginProvider = window.location.pathname.split('/')[4]
  loginProvider
    ? (loginProvider =
        loginProvider.substring(0, 1).toLocaleUpperCase() + loginProvider.substring(1))
    : (loginProvider = '')

  return (
    <form className={classes.root} onKeyDown={(e?: any) => onKeyPress(e)}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.header} data-testid='title' variant={'h4'}>
          {loginProvider} {t('authPages.oAuth2Login.header')}
        </Typography>
        {eventCodeFromQuery ? (
          <Typography className={classes.description} data-testid='description' variant={'h6'}>
            {t('authPages.oAuth2Login.proceedMessage')}
          </Typography>
        ) : (
          <Typography className={classes.description} data-testid='description' variant={'h6'}>
            {t('authPages.oAuth2Login.description')}
          </Typography>
        )}
      </div>
      <div className={classes.inputContainer}>
        {!eventCodeFromQuery && (
          <FormTextField
            title={t('authPages.oAuth2Login.eventCode')}
            placeholder={t('authPages.logInForm.eventCode.placeholder')}
            variant='standard'
            color='primary'
            value={eventCode}
            autoComplete={'off'}
            isPasswordField={false}
            error={eventFieldError}
            InputProps={{ classes: { input: classes.input } }}
            errorMessage={t('authPages.logInForm.eventCode.errorMessage')}
            onChange={onEventValueChange}
            id='oAuth-event-code'
          />
        )}
      </div>

      {errorMessage && errorMessage.length && (
        <Typography variant='subtitle1' className={classes.errorStatus}>
          {errorMessage}
        </Typography>
      )}
      <ShadowedButton
        text={t('authPages.logInForm.button.login')}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        disabled={!eventCode}
        onClick={onClick}
        id='oAuth-login-submit'
      ></ShadowedButton>
    </form>
  )
}

export default OAuth2Login
